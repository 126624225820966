import { handleResponse, SERVER_HOST } from "@api";
import { TPaymentMethod } from "@components/types";
import axios from "axios";

export const getStorePayments = async (store_id: number) => {
    const res = await axios.get(`${SERVER_HOST}/api/v1/storepayments/?store_id=${store_id}`);
    const payments: TPaymentMethod[] = handleResponse(res)
    return payments;

}

export const addStorePayment = async (payment: TPaymentMethod) => {
    let axiosMethod = payment.id == null ? axios.post : axios.put;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/storepayments/${payment.id == null ? "" : payment.id}`, JSON.stringify(payment),
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}` } })
    return handleResponse(res);
}

export const deleteStorePayment = async (id: number) => {
    const res = await axios.delete(`${SERVER_HOST}/api/v1/storepayments/${id}`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}` } });
    const payments: TPaymentMethod[] = handleResponse(res)
    return payments;
}

export const handlePayment = async (transaction_id: string, return_url: string = "") => {
    const checkLoaclhost = typeof (window) !== "undefined" && window.location.hostname.includes("localhost")
    const response = await axios.get(`${SERVER_HOST}/api/v1/ordertransactions/payment/collect?transaction_id=${transaction_id}${return_url?.length > 0 ? `&return_url=${SERVER_HOST + return_url}` : ""}`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    });
    return handleResponse(response);
}

export const handleBookingPayment = async (
    transaction_id: string
) => {
    const response = await axios.get(`${SERVER_HOST}/api/v1/bookings/payment/collect?transaction_id=${transaction_id}`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    });
    return handleResponse(response);
}

export const verifyOrderPayment = async (orderId: string, razorpayPaymentLinkId: string, razorpayPaymentLinkReferenceId: string, razorpayPaymentLinkStatus: string, razorpayPaymentId: string, razorpaySignature: string) => {
    let axiosMethod = axios.post;
    const res = await axiosMethod(`${SERVER_HOST}/api/v1/payment/verify?razorpay_payment_link_id=${razorpayPaymentLinkId}&razorpay_payment_link_reference_id=${razorpayPaymentLinkReferenceId}&razorpay_payment_link_status=${razorpayPaymentLinkStatus}&razorpay_payment_id=${razorpayPaymentId}&razorpay_signature=${razorpaySignature}&order_id=${orderId}`, JSON.stringify({}),
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}` } })
    return handleResponse(res);
}