import { OrderSummaryCard } from '@components/cards/order/summary';
import { TBookingAsset, TOrder } from '@components/types';
import React from 'react';

interface Props {
  orders: (TOrder | TBookingAsset)[];
  title?: string;
  element?: React.ElementType;
  setOrderStage?: Function
  setDetailOrder?: Function
  noLink?: boolean
}

export const OrderSummaryContainer = ({ orders, element, title, setOrderStage, setDetailOrder, noLink = false }: Props) => {
  let RepeatElement =
    element == undefined || element == null ? OrderSummaryCard : element;
  return (
    <div className="flex w-full flex-col gap-1 rounded-t-xl">
      {title && (
        <div className="px-2 w-full bg-neutral-800 text-neutral-50 font-semibold text-lg rounded-t-xl">
          {title}
        </div>
      )}
      <div className="grid w-full grid-cols-2 lt-sm:grid-cols-1 gap-4">
        {orders &&
          (orders as TOrder[])?.map((order: TOrder) => (
            <RepeatElement noLink={noLink} order={order} key={order?.id} setOrderStage={setOrderStage}
              setDetailOrder={setDetailOrder}></RepeatElement>
          ))}
      </div>
    </div>
  );
};
